import { Button, Chip, Container, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { type ErrorResponse, Link, isRouteErrorResponse, useLocation } from 'react-router-dom';
import type { FallbackProps } from 'react-error-boundary';

/**
 * Component to render an error page. There are some supported scenarios
 * - Response thrown after fetching data. We have to unwrap the JSON body manually
 * - ErrorResponse thrown in a React Router loader. It contains unwrapped data
 * - Any other Error
 */
export function Fallback({ error: routeError, resetErrorBoundary }: FallbackProps) {
  const location = useLocation();
  const errorLocation = useRef(location.pathname);
  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary();
    }
  }, [location.pathname, resetErrorBoundary]);

  // Unwrap non-route error Reponses
  const [error, setError] = useState<ErrorResponse>();
  useEffect(() => {
    // This cannot be tested since we cannot construct an instance of RouteErrorResponse.
    /* v8 ignore next 2 */
    if (isRouteErrorResponse(routeError)) {
      setError(routeError);
    } else if (routeError instanceof Response) {
      const isJson = routeError.headers.get('Content-Type')?.includes('application/json');
      (isJson
        ? routeError.clone().json()
        : routeError
            .clone()
            .text()
            .then(message => ({ message }))
      ).then(data => {
        setError({
          data,
          status: routeError.status,
          statusText: routeError.statusText,
        });
      });
    }
  }, [routeError]);

  let title, message;
  if (error) {
    title = { 401: 'Geen toegang', 403: 'Geen toegang', 404: 'Niet gevonden' }[error.status] ?? error.statusText;

    let detail;
    try {
      detail = error.data.detail && JSON.parse(error.data.detail);
    } catch (e) {
      detail = { message: error.data.detail.toString() };
    }

    message =
      error.data.message ??
      (detail && (
        <Stack direction="row" spacing={1} mb={4}>
          {detail.code && <Chip color="error" label={detail.code} size="small" variant="outlined" />}{' '}
          {detail.reason && <strong>{detail.reason}</strong>}
          <span>{detail.message}</span>
        </Stack>
      )) ??
      {
        401: 'Je moet ingelogd zijn',
        403: 'Je hebt niet de juiste rechten',
        404: 'Misschien heb je een verkeerde link gevolgd?',
      }[error.status] ??
      'Neem contact op als dit vaker gebeurt.';
  } else {
    title = 'Oeps... er ging iets mis!';
    // error is not ErrorResponse. Then routeError is not ErrorResponse | Response and therefore Error
    message = routeError.message;
  }

  return (
    <Container sx={{ my: 4 }}>
      <Typography mb={4} variant="h1">
        {title}
      </Typography>
      {typeof message === 'string' ? <Typography mb={4}>{message}</Typography> : message}
      <Button component={Link} to="/">
        Terug naar de home page
      </Button>
    </Container>
  );
}

export default Fallback;
