import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useGetUserV2 } from '#edsn/api/idm-bff';

export function useProfile() {
  const { accessTokenPayload } = useOidcAccessToken();

  const res = useGetUserV2(accessTokenPayload?.sub, {
    query: {
      enabled: !!accessTokenPayload,
    },
  });

  return {
    ...res,
    isLoading: accessTokenPayload?.sub ? res.isLoading : false,
    name: res.data && [res.data.givenName, res.data.familyName].filter(Boolean).join(' '),
  };
}
