import { TokenRenewMode } from '@axa-fr/oidc-client';
import type { OidcConfiguration } from '@axa-fr/oidc-client';

export const oidcConfiguration: OidcConfiguration = {
  authority: import.meta.env.VITE_MOCK_IDP === 'true' ? 'http://localhost:4220' : import.meta.env.VITE_IDP_URI,
  client_id: 'ciam-ui',
  redirect_uri: `${document.location.origin}/oauth/handler`,
  scope: 'openid profile roles email fr:idm:* token_v2 edsn',
  service_worker_only: true,
  service_worker_relative_url: import.meta.env.VITE_MOCK_IDP === 'true' ? undefined : '/OidcServiceWorker.js',
  silent_redirect_uri: `${window.location.origin}/oauth/silent-callback`,
  token_renew_mode: TokenRenewMode.access_token_invalid,
};
