import type {} from '@mui/lab/themeAugmentation';
import { alpha, createTheme } from '@mui/material';
import { nlNL } from '@mui/material/locale';
import type {} from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/material/styles' {
  interface TypeText {
    accent?: string;
  }
}

export const theme = createTheme(
  {
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: '#005095',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            backgroundColor: '#fafafa',
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          focusRipple: false,
          variant: 'contained',
        },
        styleOverrides: {
          outlinedPrimary: ({ theme }) => ({
            '&:hover': {
              background: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
            borderColor: theme.palette.primary.main,
          }),
          outlinedSecondary: ({ theme }) => ({
            '&:hover': {
              background: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
            },
            borderColor: theme.palette.secondary.main,
          }),
          root: ({ ownerState, theme }) => {
            const color = !ownerState.color || ownerState.color === 'inherit' ? 'primary' : ownerState.color;

            return {
              '&.active': {
                textDecoration: `underline 2px ${alpha(theme.palette[color].main, 0.35)}`,
                textUnderlineOffset: '0.25rem',
              },
              borderRadius: 9999,
              lineHeight: 1,
              textTransform: 'none',
              whiteSpace: 'nowrap',
            };
          },
          sizeLarge: {
            fontSize: '1.25rem',
            padding: '1rem 2rem',
          },
          sizeMedium: {
            fontSize: '1rem',
            padding: '.7rem 1.4rem',
          },
          sizeSmall: {
            padding: '.3rem .6rem',
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: '3rem',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            ':first-of-type': {
              paddingTop: '3rem',
            },
            ':last-of-type': {
              paddingBottom: '3rem',
            },
            paddingLeft: '3rem',
            paddingRight: '3rem',
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: 'xl',
        },
      },
      MuiCssBaseline: {
        styleOverrides: theme => ({
          body: {
            backgroundColor: theme.palette.background.default,
          },
        }),
      },
      MuiDataGrid: {
        styleOverrides: {
          columnHeaderTitle: {
            fontWeight: 'bold',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: 'none',
          },
        },
      },
      MuiLoadingButton: {
        defaultProps: {
          variant: 'contained',
        },
      },
    },
    palette: {
      background: {
        default: '#f5f5f5',
        paper: '#ffffff',
      },
      primary: {
        contrastText: '#ffffff',
        main: '#005095',
      },
      secondary: {
        contrastText: '#ffffff',
        main: '#008789',
      },
      text: { accent: '#005095' }, // Headers
    },
    typography: palette => ({
      body1: {
        li: {
          marginBottom: '1rem',
        },
        lineHeight: 1.75,
      },
      body2: {
        lineHeight: 1.75,
      },
      fontFamily: ['chevin-pro', 'sans-serif'].join(','),
      h1: {
        color: palette.text.accent,
        fontSize: '3rem',
        fontWeight: 600,
      },
      h2: {
        color: palette.text.accent,
        fontSize: '2.5rem',
        fontWeight: 600,
      },
      h3: {
        color: palette.text.accent,
        fontSize: '1.75rem',
        fontWeight: 600,
      },
      h4: {
        color: palette.text.accent,
        fontSize: '1.5rem',
        fontWeight: 600,
      },
      h5: {
        color: palette.text.accent,
        fontSize: '1.25rem',
        fontWeight: 600,
      },
      h6: {
        color: palette.text.accent,
        fontSize: '1rem',
        fontWeight: 600,
      },
    }),
  },
  nlNL
);
