import { Box, CircularProgress } from '@mui/material';

export function AuthSpinner() {
  return (
    <Box
      sx={{
        alignItems: { lg: 'center', xs: 'start' },
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 272px)',
        paddingBottom: 0,
        paddingTop: 0,
        px: 3,
        py: 16,
      }}
    >
      <CircularProgress />
    </Box>
  );
}
