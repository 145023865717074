export async function fetchInstance<T>({
  url,
  method,
  params,
  headers,
  data,
}: {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  headers?: Record<string, string>;
  // Generated types are wrong
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any;
  data?: unknown;
  responseType?: string;
  signal?: AbortSignal;
}): Promise<
  (T extends void
    ? {}
    : T extends string
      ? {
          body: string;
        }
      : T) & {
    _headers: Headers;
  }
> {
  const res = await fetch(`${import.meta.env.VITE_API_URI}${url}${params ? `?${new URLSearchParams(params)}` : ''}`, {
    headers,
    method,
    ...(data ? { body: JSON.stringify(data) } : {}),
  });

  if (!res.ok) {
    throw res;
  }

  if (res.headers.get('Content-Type') === 'application/json') {
    const json = await res.json();
    json._headers = res.headers;
    return json;
  }

  const text = await res.text();
  if (text === '') {
    return {
      _headers: res.headers,
    } as any;
  }

  return {
    _headers: res.headers,
    body: text,
  } as any;
}

export default fetchInstance;
