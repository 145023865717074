import { useOidc } from '@axa-fr/react-oidc';
import Help from '@mui/icons-material/Help';
import { AppBar, Avatar, Box, Button, Chip, Container, Toolbar } from '@mui/material';
import { Link, NavLink, Link as RouterLink } from 'react-router-dom';
import { ModelsUserResponseV2Role } from '#edsn/api/idm-bff';
import logo from '../assets/dvt.png';
import { userHasRole } from '../auth/auth';
import { useProfile } from '../pages/profile/useProfile';
import { initials } from '../utils/initials';

export function Header() {
  const { isAuthenticated } = useOidc();
  return (
    <AppBar
      color="default"
      elevation={0}
      sx={{
        backdropFilter: 'blur(10px)',
        backgroundColor: import.meta.env.VITE_ENV ? 'rgba(180, 218, 250,0.9)' : 'rgba(255,255,255,0.9)',
        borderBottomColor: 'grey.200',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Container>
          <Box alignItems="center" display="flex" gap={2}>
            <RouterLink
              to="/"
              style={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box
                alt="Dienst Veilige Toegang"
                component="img"
                src={logo}
                sx={{
                  height: 40,
                }}
              />
            </RouterLink>
            {import.meta.env.VITE_ENV && <Chip label={import.meta.env.VITE_ENV} color="info" />}
            <Box flexGrow={1} />
            {isAuthenticated && <AuthenticatedLinks />}
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export function AuthenticatedLinks() {
  const { data: user } = useProfile();

  if (!user) {
    return null;
  }

  return (
    <Box alignItems="center" display="flex" gap={1}>
      {userHasRole(
        [ModelsUserResponseV2Role.helpdesk, ModelsUserResponseV2Role.admin, ModelsUserResponseV2Role.operator],
        user
      ) && (
        <>
          <Button component={NavLink} color="primary" to="/gebruikers" variant="text">
            Gebruikers
          </Button>

          <Button component={NavLink} color="primary" to="/organisaties" variant="text">
            Organisaties
          </Button>
        </>
      )}
      {userHasRole([ModelsUserResponseV2Role.helpdesk, ModelsUserResponseV2Role.operator], user) && (
        <>
          <Button component={NavLink} color="primary" to="/service-accounts" variant="text">
            Service accounts
          </Button>
          <Button component={NavLink} color="primary" to="/rollen" variant="text">
            Applicatierollen
          </Button>
        </>
      )}
      {userHasRole(
        [ModelsUserResponseV2Role.helpdesk, ModelsUserResponseV2Role.admin, ModelsUserResponseV2Role.operator],
        user
      ) && (
        <Button component={NavLink} color="secondary" startIcon={<Help />} to="/documentatie" variant="text">
          Documentatie
        </Button>
      )}

      <Avatar
        sx={{ bgcolor: 'primary.light', ml: 4, textDecoration: 'none' }}
        className="sentry-mask"
        component={Link}
        to="/gegevens"
      >
        {initials(user)}
      </Avatar>
    </Box>
  );
}
