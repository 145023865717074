import type { ModelsUserResponseV2 } from '#edsn/api/idm-bff';
import { hasRole } from '#edsn/auth';

export function userHasRole(roles: string | string[], user: ModelsUserResponseV2 | undefined): boolean {
  if (!user) {
    return false;
  }

  const rolesList: string[] = Array.isArray(roles) ? roles : [roles];
  return hasRole(rolesList, [user.role]);
}
