import { Toolbar } from '@mui/material';
import { Header } from '../header/Header';

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Header />
      <Toolbar />
      {children}
    </>
  );
}

export default Layout;
