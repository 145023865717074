import {
  Autocomplete,
  type AutocompleteProps,
  type AutocompleteRenderInputParams,
  Chip,
  TextField,
} from '@mui/material';
import { type Ref, forwardRef } from 'react';

type ComboboxProps<T> = Omit<
  AutocompleteProps<T, true, undefined, undefined>,
  'getOptionLabel' | 'options' | 'renderInput'
> & {
  error?: boolean;
  label?: string;
  getOptionLabel: (option: T) => string;
  options: readonly T[] | undefined;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
};

/**
 *  Opiniated autocomplete multiselect with chips.
 */
export const Combobox = forwardRef(
  <T extends { id: string }>(
    {
      error,
      getOptionLabel,
      isOptionEqualToValue = (option, value) => option.id === value.id,
      label,
      options = [],
      renderInput = params => <TextField {...params} error={error} label={label} />,
      ...props
    }: ComboboxProps<T>,
    ref: Ref<unknown>
  ) => (
    // ref?: Ref<unknown> pass to <Autocomplete> once we know how to wrap Combobox in forwardRef
    <Autocomplete
      filterSelectedOptions
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      multiple
      options={options}
      ref={ref}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {getOptionLabel(option)}
        </li>
      )}
      renderInput={renderInput}
      renderTags={(selected, getTagProps) =>
        selected.map(
          (value, index) =>
            value && <Chip {...getTagProps({ index })} color="secondary" key={value.id} label={getOptionLabel(value)} />
        )
      }
      {...props}
    />
  )
);

export default Combobox;
