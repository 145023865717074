/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Identity Management
 * The Identity Management API can be used to manage application roles,
users, market participants and service accounts.

## Release notes:
### Version 0.0.1
- Initial release
 * OpenAPI spec version: 0.0.1
 */

/**
 * User Role
 */
export type ModelsUserResponseV2Role = (typeof ModelsUserResponseV2Role)[keyof typeof ModelsUserResponseV2Role];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModelsUserResponseV2Role = {
  admin: 'admin',
  operator: 'operator',
  user: 'user',
  helpdesk: 'helpdesk',
} as const;
