import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function AuthError() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        alignItems: { lg: 'center', xs: 'start' },
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
        minHeight: '100vh',
        paddingBottom: 0,
        paddingTop: 0,
        px: 3,
        py: 16,
      }}
    >
      <Alert severity="error" sx={{ display: 'inline-flex' }}>
        <AlertTitle>{t('auth_error.title')}</AlertTitle>
        <Typography>{t('auth_error.message')}</Typography>
        <Button
          variant="contained"
          color="error"
          sx={{
            mt: 2,
          }}
          href="/"
        >
          Terug
        </Button>
      </Alert>
    </Box>
  );
}
